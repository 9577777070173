import { computed, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { notEmpty } from '@cca-common/core';
import {
  ImperialUnits,
  MeasurementSystemType,
  MetricUnits,
} from '@cca-infra/common';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { filter, map } from 'rxjs';
import { AuthenticationFacade } from '../state';

/**
 * Helper function to create a system unit type signal
 * @param authentication The AuthenticationFacade
 * @returns Signal<MeasurementSystemType>
 */
function createSystemUnitTypeSignalHelper(
  authentication: AuthenticationFacade,
): Signal<MeasurementSystemType | undefined> {
  return toSignal(
    authentication.user$.pipe(
      map((x) => x?.userDetail.measurementSystemType),
      filter(notEmpty),
    ),
  );
}

/**
 * Helper function to create a symbol signal for length
 * @param systemUnitType The signal for the system unit type
 * @param locale The signal for the current locale
 * @returns Signal<string>
 */
function createSymbolSignalHelper(
  systemUnitType: Signal<MeasurementSystemType | undefined>,
  locale: Signal<string | undefined>,
  unit: string,
): Signal<string> {
  return computed(() => {
    switch (systemUnitType()) {
      case MeasurementSystemType.ImperialUS:
        return String(
          Intl.NumberFormat(locale(), {
            style: 'unit',
            unit: unit,
          })
            .formatToParts(0)
            .find((x) => x.type === 'unit')?.value,
        );
      case MeasurementSystemType.Metric:
      default:
        return String(
          Intl.NumberFormat(locale(), {
            style: 'unit',
            unit: unit,
          })
            .formatToParts(0)
            .find((x) => x.type === 'unit')?.value,
        );
    }
  });
}

/**
 * @returns Signal<string>
 */
export function createSystemUnitTypeSignal() {
  const authentication = inject(AuthenticationFacade);
  const systemUnitType = createSystemUnitTypeSignalHelper(authentication)();
  return computed(() => {
    return systemUnitType;
  });
}

/**
 * @returns Signal<string>
 */
export function createLengthSymbolSignal() {
  const authentication = inject(AuthenticationFacade);
  const translocoLocale = inject(TranslocoLocaleService);

  const systemUnitType = createSystemUnitTypeSignalHelper(authentication);
  const locale = toSignal(translocoLocale.localeChanges$);

  return createSymbolSignalHelper(
    systemUnitType,
    locale,
    systemUnitType() === MeasurementSystemType.ImperialUS
      ? ImperialUnits.Inch
      : MetricUnits.Centimeter,
  );
}

/**
 * @returns Signal<string>
 */
export function createWeightSymbolSignal() {
  const authentication = inject(AuthenticationFacade);
  const translocoLocale = inject(TranslocoLocaleService);

  const systemUnitType = createSystemUnitTypeSignalHelper(authentication);
  const locale = toSignal(translocoLocale.localeChanges$);

  return createSymbolSignalHelper(
    systemUnitType,
    locale,
    systemUnitType() === MeasurementSystemType.ImperialUS
      ? ImperialUnits.Pound
      : MetricUnits.KiloGram,
  );
}
