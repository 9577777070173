import { DerivedAuthViewModel } from '@cca-infra/user-management/v1';
import { createAction, props } from '@ngrx/store';

// refresh token
export type OptionalProperty = { showMessage?: boolean };
export const refreshAccessToken = createAction(
  '[Authentication] refreshAccessToken',
  props<{ showMessage?: boolean; redirect?: boolean }>(),
);
export const refreshAccessTokenSuccess = createAction(
  '[Authentication] refreshAccessTokenSuccess',
  props<{ authentication: DerivedAuthViewModel }>(),
);
export const refreshAccessTokenFailure = createAction(
  '[Authentication] refreshAccessTokenFailure',
  props<{ error: string; showMessage: boolean; redirect: boolean }>(),
);
