import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import * as AuthenticationActions from './authentication.actions';
import { Store } from '@ngrx/store';
import { AuthenticationState } from './authentication.state';
import {
  selectAll,
  selectAuthenticated,
  selectAuthenticationInProgress,
  selectEmail,
  selectError,
  selectPermissions,
  selectProjectedUser,
  selectRealPermissions,
  selectRealUser,
  selectRefreshTokenInProgress,
} from './authentication.selectors';
import {
  DerivedAuthViewModel,
  PermissionKey,
  PermissionType,
  SystemFeature,
} from '@cca-infra/user-management/v1';
import { checkFeature, checkPermission } from '../util';
import { UserAuthenticationRequestModel } from '../model';

@Injectable()
export class AuthenticationFacade {
  private store = inject(Store);

  readonly authenticated$ = this.store.select(selectAuthenticated);

  readonly authenticated = this.store.selectSignal(selectAuthenticated);

  readonly realUser$ = this.store.select(selectRealUser);
  readonly realUser = this.store.selectSignal(selectRealUser);

  readonly user$ = this.store.select(selectProjectedUser);
  readonly user = this.store.selectSignal(selectProjectedUser);

  readonly error$ = this.store.select(selectError);
  readonly error = this.store.selectSignal(selectError);

  readonly authenticationInProcess$ = this.store.select(
    selectAuthenticationInProgress,
  );
  readonly authenticationInProgress = this.store.selectSignal(
    selectAuthenticationInProgress,
  );

  readonly refreshTokenInProcess$ = this.store.select(
    selectRefreshTokenInProgress,
  );
  readonly refreshTokenInProcess = this.store.selectSignal(
    selectRefreshTokenInProgress,
  );

  readonly email = this.store.selectSignal(selectEmail);

  permissions$ = this.store.select(selectPermissions);
  permissions = this.store.selectSignal(selectPermissions);
  realPermissions = this.store.selectSignal(selectRealPermissions);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(mapper = (s: AuthenticationState | undefined) => s as any): any {
    return mapper(this.store.selectSignal(selectAll)());
  }

  authenticate(authenticationModel: UserAuthenticationRequestModel) {
    if (!this.authenticationInProgress()) {
      this.store.dispatch(
        AuthenticationActions.authenticateAction(authenticationModel),
      );
    }
  }

  authenticateSso(accessToken: string) {
    this.store.dispatch(
      AuthenticationActions.authenticateSsoAction({ accessToken: accessToken }),
    );
  }

  hasPermission$(
    permissionKey: PermissionKey,
    permissionType?: PermissionType,
  ) {
    return this.permissions$.pipe(
      map((userPermissions) => {
        return checkPermission(userPermissions, permissionKey, permissionType);
      }),
    );
  }

  hasPermissions$(
    permissionKey: PermissionKey[],
    permissionType?: PermissionType[],
  ) {
    return this.permissions$.pipe(
      map((permissionEntries) => {
        return checkPermission(
          permissionEntries,
          permissionKey,
          permissionType,
        );
      }),
    );
  }

  checkPermission(
    permissions: PermissionKey | PermissionKey[],
    permissionType?: PermissionType | undefined,
  ): boolean {
    const permissionEntries = this.permissions();
    return checkPermission(permissionEntries, permissions, permissionType);
  }

  checkRealPermission(
    permissions: PermissionKey | PermissionKey[],
    permissionType?: PermissionType | undefined,
  ): boolean {
    const permissionEntries = this.realPermissions();
    return checkPermission(permissionEntries, permissions, permissionType);
  }

  checkFeature(features: SystemFeature | SystemFeature[]): boolean {
    const userFeatures = this.get(
      (s) => (s?.projectedUser ?? s?.realUser)?.enabledFeatures,
    );
    return checkFeature(userFeatures, features);
  }

  projectionUser(user: DerivedAuthViewModel) {
    this.store.dispatch(
      AuthenticationActions.projectionChange({
        user: user,
      }),
    );
  }

  projectionClear() {
    this.store.dispatch(AuthenticationActions.projectionClear());
  }

  clear() {
    this.store.dispatch(AuthenticationActions.authenticateClearProcess());
  }

  logout() {
    // broadcast this action to other tabs
    this.store.dispatch(AuthenticationActions.logout());
  }

  refreshAccessToken(config?: { showMessage: boolean; redirect: boolean }) {
    if (!this.refreshTokenInProcess()) {
      this.store.dispatch(
        AuthenticationActions.refreshAccessToken({
          showMessage: config?.showMessage ?? true,
          redirect: config?.redirect ?? true,
        }),
      );
    }
  }
}
