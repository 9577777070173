import { createAction, props } from '@ngrx/store';

// logout
export type RedirectProperty = { redirect: boolean };
export const logout = createAction('[Authentication] logout');

export const logoutComplete = createAction(
  '[Authentication] logoutComplete',
  props<{ showMessage?: boolean; redirect?: boolean }>(),
);
