import { ApplicationRef, Injectable, inject } from '@angular/core';
import { RouterFacade } from '@cca-common/cdk';
import { Snackbar } from '@cca-common/snackbar';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { exhaustMap, map, catchError, of, tap } from 'rxjs';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { AuthenticationConfigToken } from '../../authentication-config.token';
import * as loginActions from './login.actions';
import * as synchronizeActions from '../synchronize/synchronize.actions';
import {
  AuthenticationService,
  toAuthModel,
} from '@cca-infra/user-management/v1';
import { UserAuthenticationRequestModel } from '../../model';

@Injectable()
export class AuthenticationLoginEffects {
  private config = inject(AuthenticationConfigToken);
  private actions$ = inject(Actions);
  private authService = inject(AuthenticationService);
  private router = inject(RouterFacade);
  private snackbar = inject(Snackbar);
  private applicationRef = inject(ApplicationRef);

  // on login, connect to api
  readonly login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.authenticateAction),
      exhaustMap((user: UserAuthenticationRequestModel) => {
        return this.authService.login(user.username, user.password).pipe(
          map((authenticationData) => {
            return loginActions.authenticateSuccessAction({
              authentication: toAuthModel(authenticationData),
            });
          }),
          catchError(() => {
            this.snackbar.openError(
              t('authentication.snackbar.authenticationError'),
            );
            return of(
              loginActions.authenticateFailureAction({
                error: 'Error failed to authenticate',
              }),
            );
          }),
        );
      }),
    );
  });

  readonly loginSso$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.authenticateSsoAction),
      exhaustMap((params: { accessToken: string }) => {
        return this.authService.loginSso(params.accessToken);
      }),
      map((authenticationData) =>
        loginActions.authenticateSuccessAction({
          authentication: toAuthModel(authenticationData),
        }),
      ),
      catchError(() => {
        this.snackbar.openError(
          t('authentication.snackbar.authenticationError'),
        );
        return of(
          loginActions.authenticateFailureAction({
            error: 'Error failed to authenticate',
          }),
        );
      }),
    );
  });

  // on success navigate to /
  readonly loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          loginActions.authenticateSuccessAction,
          synchronizeActions.SynchronizeAuthenticateSuccessAction,
        ),
        concatLatestFrom(() => this.router.queryParam$('url')),
        tap((results) => {
          const urlQuery = results[1];
          const url = Array.isArray(urlQuery) ? urlQuery.at(0) : urlQuery;
          if (url) {
            this.router.navigateByUrl(url);
          } else {
            this.router.navigateByUrl(this.config.urlAfterLogin);
          }
        }),
        tap(() => this.applicationRef.tick()),
      );
    },
    {
      dispatch: false,
    },
  );
}
