import { FormControl, FormGroup, Validators } from '@angular/forms';
import { platformEmailValidator } from '@cca-common/core';

export const RequestResetPasswordFormFactory = () => {
  return new FormGroup({
    email: new FormControl<string | null>(null, {
      validators: [Validators.required, platformEmailValidator()],
    }),
  });
};

export type RequestResetPasswordForm = ReturnType<
  typeof RequestResetPasswordFormFactory
>;
