import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import * as AuthenticationActions from '../authentication.actions';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    smartlook: any;
  }
}

@Injectable()
export class AuthenticationSmartlookEffects {
  private actions$ = inject(Actions);

  readonly smartLookIdentify = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthenticationActions.authenticateSuccessAction,
          AuthenticationActions.refreshAccessTokenSuccess,
        ),
        tap((results) => {
          const authData = results.authentication;
          window.smartlook(
            'identify',
            authData.userId,
            authData.smartlookIdentity,
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  readonly smartLookIdentifyRemove = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logoutComplete),
        tap(() => {
          window.smartlook('anonymize');
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
