import { Injectable, NgZone, OnDestroy, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { BroadcastMessageTypes, BroadcastMessages } from './broadcast-messages';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationBroadcastChannel
  extends Subject<BroadcastMessages>
  implements OnDestroy
{
  private store = inject(Store);
  private zone = inject(NgZone);

  private broadcastChannel: BroadcastChannel | undefined;

  constructor() {
    super();
    if (BroadcastChannel) {
      this.zone.runOutsideAngular(() => {
        try {
          // setup new broadcastChannel, and listen to new messages from the channel
          this.broadcastChannel = new BroadcastChannel('Authentication');
          this.broadcastChannel.addEventListener('message', (event) => {
            this.next(event.data as BroadcastMessages);
          });
        } catch (e) {
          console.error(e);
          //do some alternative here...
        }
      });
    }

    // subscribe for messages
    this.subscribe((message) => {
      this.zone.run(() => {
        switch (message.type) {
          // if the message type is action, then the 'action' property should be a ngrx action
          // so we can dispatch that
          case BroadcastMessageTypes.Action:
            this.store.dispatch(message.action);
            break;

          default:
            return;
        }
      });
    });
  }

  postMessage(message: BroadcastMessages) {
    this.zone.runOutsideAngular(() => {
      this.broadcastChannel?.postMessage(message);
    });
  }

  ngOnDestroy(): void {
    this.broadcastChannel?.close();
  }
}
