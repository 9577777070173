import { on } from '@ngrx/store';
import { AuthenticationState } from '../authentication.state';
import {
  authenticateAction,
  authenticateFailureAction,
  authenticateSuccessAction,
} from './login.actions';
import * as synchronizeActions from '../synchronize/synchronize.actions';

export const loginReducer = [
  // on login set loading to true, and error to false
  on(
    authenticateAction,
    synchronizeActions.SynchronizeAuthenticateAction,
    (state: AuthenticationState): AuthenticationState => {
      return {
        ...state,
        authenticationInProgress: true,
        error: null,
      };
    },
  ),

  on(
    authenticateSuccessAction,
    synchronizeActions.SynchronizeAuthenticateSuccessAction,
    (
      state: AuthenticationState,
      { authentication: userData },
    ): AuthenticationState => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return {
        ...state,
        authenticationInProgress: false,
        realUser: userData,
      };
    },
  ),

  on(
    authenticateFailureAction,
    synchronizeActions.SynchronizeAuthenticateFailureAction,
    (state: AuthenticationState, { error }): AuthenticationState => {
      return {
        ...state,
        realUser: null,
        projectedUser: null,
        authenticationInProgress: false,
        error: error,
      };
    },
  ),
];
