import { ModuleWithProviders, NgModule, inject } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthenticationConfigToken } from './authentication-config.token';
import { AuthenticationFacade } from './state';
import { AuthenticationInterceptor } from './authentication.interceptor';
import { ChaincargoModule, LoggedInSignalToken } from '@cca-infra/core';
import {
  AuthenticationSynchronizeEffects,
  AuthenticationLoginEffects,
  AuthenticationLogoutEffects,
  AuthenticationRefreshEffects,
  AuthenticationUserEffects,
  AuthenticationInitEffects,
  AuthenticationSmartlookEffects,
} from './state/authentication.effects';
import { AuthenticationConfig } from '@cca-common/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { TermsOfUseEffects } from './state/terms-of-use/terms-of-use.effects';

@NgModule({
  imports: [
    ChaincargoModule.forRoot(),
    EffectsModule.forFeature([
      // The order of the effects do matter, do not change the order of effects without testing/verifying that nothing breaks
      AuthenticationLoginEffects,
      AuthenticationLogoutEffects,
      AuthenticationRefreshEffects,
      AuthenticationUserEffects,
      AuthenticationSmartlookEffects,
      AuthenticationSynchronizeEffects,
      // make sure the initEffects are registered last, else possible triggers in other effects are not triggered
      AuthenticationInitEffects,
      TermsOfUseEffects,
    ]),
    MatDialogModule,
  ],
  providers: [
    AuthenticationFacade,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AuthenticationModule {
  static forRoot(
    config: AuthenticationConfig,
  ): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useFactory() {
            return new AuthenticationInterceptor();
          },
          multi: true,
        },
        {
          provide: AuthenticationConfigToken,
          useValue: config,
        },
        AuthenticationFacade,
        {
          provide: LoggedInSignalToken,
          useFactory: () => inject(AuthenticationFacade).authenticated,
        },
      ],
    };
  }
}
