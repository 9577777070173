import { DerivedAuthViewModel } from '@cca-infra/user-management/v1';
import { createAction, props } from '@ngrx/store';

// login
export const SynchronizeAuthenticateAction = createAction(
  '[Authentication] login from other context',
);

export const SynchronizeAuthenticateFailureAction = createAction(
  '[Authentication] loginFailure from other context',
  props<{ error: string }>(),
);

export const SynchronizeAuthenticateSuccessAction = createAction(
  '[Authentication] loginSuccess from other context',
  props<{ authentication: DerivedAuthViewModel }>(),
);

// refresh
export const SynchronizeRefreshAccessToken = createAction(
  '[Authentication] refreshAccessToken from other context',
);

export const SynchronizeRefreshAccessTokenFailure = createAction(
  '[Authentication] refreshAccessTokenFailure from other context',
  props<{ error: string }>(),
);

export const SynchronizeRefreshAccessTokenSuccessAction = createAction(
  '[Authentication] refreshAccessTokenSuccess from other context',
  props<{ authentication: DerivedAuthViewModel }>(),
);

// logout
export const SynchronizeLogoutCompleteAction = createAction(
  '[Authentication] logoutComplete from other context',
);

// timeouts
export const clearAuthenticationProgressAction = createAction(
  '[Authentication] clear authentication progress',
);

export const clearRefreshProgressAction = createAction(
  '[Authentication] clear refresh progress',
);
