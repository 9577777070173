import { PermissionKey, PermissionType } from '@cca-infra/user-management/v1';

export type PermissionData = Record<string, number>;

export function checkPermission(
  permissions: PermissionData,
  keys: PermissionKey | PermissionKey[],
  types?: PermissionType | PermissionType[] | undefined,
): boolean {
  // [key] and [[key]] becomes [key] with flat
  const checkKeys = [keys].flat();

  // same for types we create a array of types to check against, then we reduce this to a single number:
  // if our types would be 1, 2, 8 or otherwise written in binary: 0b0001, 0b0010 and, 0b1000
  // by reducing this with a bitwise OR we get a new number: 0b1011 we will compare this number with the permission within the permissionEntries
  // if we don't provided a type to check against we check against MAX_SAFE_INTEGER meaning all bits are set to 1
  const checkTypes =
    [types].flat().reduce((acc, cur) => acc | (cur ?? 0), 0) ||
    Number.MAX_SAFE_INTEGER;

  // for each given key check if it exist in our permissionEntries
  return checkKeys.some((checkKey) => {
    // if it exist also check the types
    if (checkKey in permissions) {
      const entry = permissions[checkKey];
      return entry & checkTypes;
    }

    // else its false
    return false;
  });
}
