import { Injectable, inject } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, from, of, switchMap, timer } from 'rxjs';
import * as refreshActions from '../refresh/refresh.actions';
import * as userActions from '../user/user.actions';
import * as initActions from './initialize.actions';
import { Action, Store } from '@ngrx/store';
import {
  selectAuthenticationInProgress,
  selectRefreshTokenInProgress,
} from '../authentication.selectors';
import { authenticationTimeOutInMs } from '../constants';

@Injectable()
export class AuthenticationInitEffects implements OnInitEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);

  // authentication signals
  private refreshingInProgress = this.store.selectSignal(
    selectRefreshTokenInProgress,
  );
  private authenticationInProgress = this.store.selectSignal(
    selectAuthenticationInProgress,
  );

  readonly checkProgressIndicators$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initActions.initializeAction),
      switchMap(() => {
        // if we're currently logging or are refreshing, check in 20_000 ms if we're still in that state
        if (this.authenticationInProgress() || this.refreshingInProgress()) {
          return timer(authenticationTimeOutInMs).pipe(
            switchMap(() => {
              // if we still are in that state it is possible that the pc/browser/tab crashed or in other ways closed before it put back to false
              // therefore this is a failsafe, to put it back to it's initial state of false
              if (
                this.authenticationInProgress() ||
                this.refreshingInProgress()
              ) {
                return of(initActions.authenticateClearProcess());
              }

              return EMPTY;
            }),
          );
        }
        return EMPTY;
      }),
    );
  });

  readonly otherActions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initActions.initializeAction),
      switchMap(() => {
        const actions: Action[] = [userActions.syncUserLanguageWithTransloco()];

        // if refresh is not in progress, refresh one might be sometime since we last updated our permission/userData
        // imagine coming back after few days and your permissions have been updated, we should be up to date
        // but it is possible that we have another tab open and that one is currently refreshing
        if (!this.refreshingInProgress()) {
          actions.push(
            refreshActions.refreshAccessToken({
              redirect: false,
              showMessage: false,
            }),
          );
        }

        return from(actions);
      }),
    );
  });

  ngrxOnInitEffects() {
    return initActions.initializeAction();
  }
}
