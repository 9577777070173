import { Validators, FormControl, FormGroup } from '@angular/forms';
import { confirmPasswordValidator, passwordValidator } from '@cca-common/core';

export const ResetPasswordFormFactory = () => {
  return new FormGroup(
    {
      password: new FormControl<string | null>(null, {
        validators: [Validators.required, passwordValidator()],
      }),
      confirmPassword: new FormControl<string | null>(null, {
        validators: [Validators.required],
      }),
    },
    {
      validators: [confirmPasswordValidator('password', 'confirmPassword')],
    },
  );
};

export type ResetPasswordForm = ReturnType<typeof ResetPasswordFormFactory>;
