import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  UrlTree,
} from '@angular/router';
import { getPathFromRootRoute, RouterFacade } from '@cca-common/cdk';
import { AuthenticationFacade } from '../state/authentication.facade';
import { PermissionKey, PermissionType } from '@cca-infra/user-management/v1';

export const permissionGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const guard = inject(PermissionsGuard);
  return guard.canActivate(route);
};

@Injectable({
  providedIn: 'root',
})
class PermissionsGuard {
  private authentication: AuthenticationFacade = inject(AuthenticationFacade);
  private router: RouterFacade = inject(RouterFacade);

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const routePermissionKey = route.data.permissionKey as
      | PermissionKey
      | PermissionKey[];
    const routePermissionType = route.data.permissionType as PermissionType;
    const routeRedirection =
      (route.data.redirection as boolean | undefined) ?? false;
    const routeRealPermission = route.data.realPermission as boolean;

    if (routePermissionKey) {
      const access = this.checkPermission(
        routePermissionKey,
        routePermissionType,
        routeRealPermission,
      );

      if (!access) {
        return routeRedirection
          ? this.router.createUrlTree([
              routeRedirection ?? getPathFromRootRoute(route),
            ])
          : false;
      }
    }
    return true;
  }

  private checkPermission(
    routePermissionKey: PermissionKey | PermissionKey[],
    routePermissionType: PermissionType | undefined,
    realPermission: boolean,
  ) {
    if (realPermission) {
      return this.authentication.checkRealPermission(
        routePermissionKey,
        routePermissionType,
      );
    } else {
      return this.authentication.checkPermission(
        routePermissionKey,
        routePermissionType,
      );
    }
  }
}
