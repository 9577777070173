import {
  assertInInjectionContext,
  inject,
  InjectionToken,
  Injector,
  isDevMode,
  runInInjectionContext,
} from '@angular/core';
import { PermissionKey, PermissionType } from '@cca-infra/user-management/v1';
import { createPermissionSignal } from '.';

// Types holding all possible properties of our permission Enums
type PermissionKeyProperty = keyof typeof PermissionKey;
type PermissionTypeProperty = keyof typeof PermissionType;

// Type for PermissionSignal
type PermissionSignal = ReturnType<typeof createPermissionSignal>;

// Nested Signal is a extension of PermissionSignal, holding a PermissionSignal and possible other properties for accessing permissionTypes eg 'Create'
type NestedPermissionSignal = PermissionSignal &
  Record<PermissionTypeProperty, PermissionSignal>;

// Type for our created Service, this will be a Empty Object at start, and will lazily create properties when they are needed
export type PermissionServiceType = Record<
  PermissionKeyProperty,
  NestedPermissionSignal
>;

// function for creating nested Permission Signals by use of a proxy
// to intercept property access and create permission Signals for types on the fly
function createNestedPermissionProxy(injector: Injector, key: PermissionKey) {
  isDevMode() && assertInInjectionContext(createNestedPermissionProxy);

  // create our permissionSignal
  const permissionSignal = createPermissionSignal(key);

  // create proxy around it
  return new Proxy(permissionSignal as NestedPermissionSignal, {
    get(target, typeProp: keyof NestedPermissionSignal) {
      // return if target has property
      if (Reflect.has(target, typeProp)) {
        return Reflect.get(target, typeProp, target);
      }

      // make property since it is requested
      if (typeProp in PermissionType) {
        const type = PermissionType[typeProp as keyof typeof PermissionType];
        return runInInjectionContext(injector, () => {
          const typeSignal = createPermissionSignal(key, type);
          Reflect.defineProperty(target, typeProp, {
            value: typeSignal,
            writable: false,
            configurable: false,
            enumerable: true,
          });
          return typeSignal;
        });
      }

      return undefined;
    },
  });
}

// Factory function for creating the PermissionService, the service itself is a Empty Object which lazily creates the signals when needed
// by the use of a proxy
function PermissionServiceFactory() {
  isDevMode() && assertInInjectionContext(PermissionServiceFactory);

  // save reference to injector for future usages
  const injector = inject(Injector);

  return new Proxy<PermissionServiceType>({} as PermissionServiceType, {
    get(target, keyProp: keyof PermissionServiceType) {
      // Return if property exist
      if (Reflect.has(target, keyProp)) {
        return Reflect.get(target, keyProp, target);
      }

      // check if is a permissionKey, proxy.set could be called for ngOnDestroy/constructor
      if (keyProp in PermissionKey) {
        const key = PermissionKey[keyProp as keyof typeof PermissionKey];
        return runInInjectionContext(injector, () => {
          if (!key) {
            return target[keyProp];
          }
          const nestedPermissionProxy = createNestedPermissionProxy(
            injector,
            key,
          );

          Reflect.defineProperty(target, keyProp, {
            value: nestedPermissionProxy,
            writable: false,
            configurable: false,
            enumerable: true,
          });

          return nestedPermissionProxy;
        });
      }

      return undefined;
    },
  });
}

/**
 * PermissionService
 *
 * Lazily creates permissions signals
 */
export const PermissionService = new InjectionToken<PermissionServiceType>(
  'PermissionsService',
  {
    providedIn: 'root',
    factory: PermissionServiceFactory,
  },
);
