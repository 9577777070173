import { DerivedAuthViewModel } from '@cca-infra/user-management/v1';

export interface AuthenticationState {
  // user properties
  realUser: DerivedAuthViewModel | null;
  projectedUser: DerivedAuthViewModel | null;

  // process
  authenticationInProgress: boolean;
  refreshingTokenInProgress: boolean;
  error: string | null;
}

export const initialState: AuthenticationState = {
  // user properties
  realUser: null,
  projectedUser: null,

  // process
  authenticationInProgress: false,
  refreshingTokenInProgress: false,
  error: null,
};
