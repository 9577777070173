import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from './authentication.state';
import { authenticationKey } from './authentication.reducer';
import { isAuthViewModel, toAuthModel } from '@cca-infra/user-management/v1';

export const selectAuthenticationState =
  createFeatureSelector<AuthenticationState>(authenticationKey);

export const selectAuthenticated = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => !!state.realUser,
);

export const selectRealUser = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) =>
    isAuthViewModel(state.realUser)
      ? toAuthModel(state.realUser)
      : state.realUser,
);

export const selectRealPermissions = createSelector(
  selectRealUser,
  (user) => user?.permissions ?? {},
);

export const selectRealPermissionEntries = createSelector(
  selectRealPermissions,
  (permissions) => Object.entries(permissions),
);

// if accessToken or refreshingTokenInProcess we want to give either projection or real user
export const selectProjectedUser = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => {
    const user = state?.projectedUser ?? state.realUser;
    return isAuthViewModel(user) ? toAuthModel(user) : user;
  },
);

export const selectPermissions = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) =>
    (state?.projectedUser ?? state.realUser)?.permissions ?? {},
);

export const selectPermissionEntries = createSelector(
  selectPermissions,
  (permissions) => Object.entries(permissions),
);

export const selectError = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.error,
);

export const selectAuthenticationInProgress = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.authenticationInProgress,
);

export const selectRefreshTokenInProgress = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.refreshingTokenInProgress,
);

export const selectAll = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state,
);

export const selectEmail = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.realUser?.email,
);

export const selectLanguage = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.realUser?.languageName,
);

export const selectLocale = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.realUser?.locale,
);

export const selectClockPreference = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.realUser?.clockPreferenceType,
);
