import { Injectable, inject } from '@angular/core';
import { RouterFacade } from '@cca-common/cdk';
import { Snackbar } from '@cca-common/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { AuthenticationConfigToken } from '../../authentication-config.token';
import * as logoutActions from './logout.actions';
import * as refreshActions from '../refresh/refresh.actions';
import * as synchronizeActions from '../synchronize/synchronize.actions';
import { AuthenticationService } from '@cca-infra/user-management/v1';
import { WINDOW } from '@cca-common/environment';

@Injectable()
export class AuthenticationLogoutEffects {
  private config = inject(AuthenticationConfigToken);
  private actions$ = inject(Actions);
  private authService = inject(AuthenticationService);
  private router = inject(RouterFacade);
  private snackbar = inject(Snackbar);
  private window = inject(WINDOW);

  // on logout, we might wanna have our authService call backend let them know hey we're gone
  // then dispatch logoutComplete()
  readonly logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logoutActions.logout, refreshActions.refreshAccessTokenFailure),
      // notify backend
      tap((action) => {
        if (action.type === logoutActions.logout.type) {
          // on purpose calling a subscribe here, we do this to not delay the effect stream
          // if endpoint is slow we are hanging the application!
          try {
            this.authService.logout().subscribe();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.window as any)?.HubSpotConversations?.widget?.load();
          } catch {
            // just making sure effect, does not crash
          }
        }
      }),
      map((action) => {
        const showMessage = 'showMessage' in action ? action.showMessage : true;
        const redirect = 'redirect' in action ? action.showMessage : true;

        return logoutActions.logoutComplete({
          redirect: redirect,
          showMessage: showMessage,
        });
      }),
    );
  });

  // on logout navigate to login
  readonly logoutComplete$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          logoutActions.logoutComplete,
          synchronizeActions.SynchronizeLogoutCompleteAction,
        ),
        tap((action) => {
          const showMessage =
            'showMessage' in action ? !!action.showMessage : true;
          if (showMessage) {
            this.snackbar.openSuccess(
              t('authentication.snackbar.logoutMessage'),
            );
          }

          const redirect = 'redirect' in action ? !!action.redirect : true;
          if (redirect) {
            this.router.navigateByUrl(this.config.urlAfterLogout);
          }
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
