import { Action } from '@ngrx/store';

export enum BroadcastMessageTypes {
  Action = 'action',
}

export type BroadcastActionMessage = {
  type: BroadcastMessageTypes.Action;
  action: Action;
};

export type BroadcastMessages = BroadcastActionMessage;
