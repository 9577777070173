import { ActionCreator, ReducerTypes, on } from '@ngrx/store';
import { AuthenticationState, initialState } from '../authentication.state';
import {
  refreshAccessToken,
  refreshAccessTokenSuccess,
  refreshAccessTokenFailure,
} from './refresh.actions';
import * as synchronizeActions from '../synchronize/synchronize.actions';

export const refreshReducer: ReducerTypes<
  AuthenticationState,
  readonly ActionCreator[]
>[] = [
  // Refreshing access tokens
  on(
    refreshAccessToken,
    synchronizeActions.SynchronizeRefreshAccessToken,
    (state: AuthenticationState): AuthenticationState => {
      return {
        ...state,
        refreshingTokenInProgress: true,
      };
    },
  ),

  on(
    refreshAccessTokenSuccess,
    synchronizeActions.SynchronizeRefreshAccessTokenSuccessAction,
    (state: AuthenticationState, { authentication }): AuthenticationState => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ...user } = authentication;
      return {
        ...state,
        authenticationInProgress: false,
        refreshingTokenInProgress: false,
        realUser: user,
      };
    },
  ),

  on(
    refreshAccessTokenFailure,
    synchronizeActions.SynchronizeRefreshAccessTokenFailure,
    (state: AuthenticationState, { error }): AuthenticationState => {
      return {
        ...initialState,
        error: error,
        realUser: null,
        projectedUser: null,
      };
    },
  ),
];
