import { SystemFeature } from '@cca-infra/user-management/v1';

export function checkFeature(
  features: SystemFeature[] | null | undefined,
  featureKeys: SystemFeature | SystemFeature[],
): boolean {
  if (!features) {
    return false;
  }

  // create a array of feature keys to check against
  let checkFeatureKeys: SystemFeature[] = [];
  if (Array.isArray(featureKeys)) {
    checkFeatureKeys = featureKeys;
  } else {
    checkFeatureKeys = [featureKeys];
  }

  // check if at least one feature matches a feature key from our feature key array
  // if checkFeatures is undefined, we assume that the user has all features
  // if checkFeatures is defined, we check if the user has at least one matching feature type.
  return (features ?? [])?.some((feature) => {
    return checkFeatureKeys.includes(feature as SystemFeature);
  });
}
