import { on } from '@ngrx/store';
import { AuthenticationState } from '../authentication.state';
import {
  clearRefreshProgressAction,
  clearAuthenticationProgressAction,
} from './synchronize.actions';

export const synchronizeReducer = [
  // on logging out we reset state to initial state
  // on authenticateClearProcess, we want to clear indicating variables to false
  // it is possible that while someone is refreshing, they close their browser tab. Which does not reset the inProgress back to false
  on(
    clearRefreshProgressAction,
    (state: AuthenticationState): AuthenticationState => {
      return {
        ...state,
        refreshingTokenInProgress: false,
      };
    },
  ),

  on(
    clearAuthenticationProgressAction,
    (state: AuthenticationState): AuthenticationState => {
      return {
        ...state,
        authenticationInProgress: false,
        error: null,
      };
    },
  ),
];
