import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { IconComponent } from '@cca/ui';
import { MatButtonModule } from '@angular/material/button';
import {
  provideTranslocoScope,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';

@Component({
  selector: 'cca-rebranding-modal',
  standalone: true,
  imports: [IconComponent, MatDialogModule, MatButtonModule, TranslocoModule],
  providers: [provideTranslocoScope('rebranding')],
  templateUrl: './rebranding-modal.component.html',
  styleUrls: ['./rebranding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RebrandingModalComponent {
  dialogRef = inject<MatDialogRef<RebrandingModalComponent>>(MatDialogRef);

  private transloco = inject(TranslocoService);

  openLearnMorePage() {
    window.open(
      this.transloco.translate(t('rebranding.learnMoreLink')),
      '_blank',
    );
  }

  acknowledge() {
    localStorage.setItem('rebrandingModalAcknowledged', 'true');
  }
}
