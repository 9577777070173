import { createReducer, Action } from '@ngrx/store';
import { AuthenticationState, initialState } from './authentication.state';
import { loginReducer } from './login/login.reducer';
import { logoutReducer } from './logout/logout.reducer';
import { projectionReducer } from './projection/projection.reducer';
import { refreshReducer } from './refresh/refresh.reducer';
import { initializeReducer } from './initialize/initialize.reducer';
import { synchronizeReducer } from './synchronize/synchronize.reducer';

export const authenticationKey = 'authentication';

const _authenticationReducer = createReducer(
  initialState,

  // reducers have been split between the actions they do, for better readability
  ...initializeReducer,
  ...loginReducer,
  ...logoutReducer,
  ...refreshReducer,
  ...projectionReducer,
  ...synchronizeReducer,
);

export function authenticationReducer(
  state: AuthenticationState | undefined,
  action: Action,
) {
  return _authenticationReducer(state, action);
}
