import { on } from '@ngrx/store';
import { AuthenticationState } from '../authentication.state';
import { projectionChange, projectionClear } from './projection.actions';

export const projectionReducer = [
  // Projection changes
  on(
    projectionChange,
    (state: AuthenticationState, { user }): AuthenticationState => {
      return {
        ...state,
        projectedUser: { ...user },
      };
    },
  ),

  on(projectionClear, (state: AuthenticationState): AuthenticationState => {
    return {
      ...state,
      projectedUser: null,
    };
  }),
];
