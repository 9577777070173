import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TermsDialogComponent } from '@cca-common/shared-terms';
import * as loginActions from '../login/login.actions';
import * as refreshActions from '../refresh/refresh.actions';
import { AuthenticationFacade } from '../authentication.facade';
import {
  AcceptanceService,
  LegalDocumentType,
} from '@cca-infra/legal-management/v1';

@Injectable()
export class TermsOfUseEffects {
  private actions$ = inject(Actions);
  private dialog = inject(MatDialog);
  private authFacade = inject(AuthenticationFacade);
  private acceptanceService = inject(AcceptanceService);

  readonly openTermsOfUseDialogOnLogIn$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          loginActions.authenticateSuccessAction,
          refreshActions.refreshAccessTokenSuccess,
        ),
        switchMap(() => {
          return this.acceptanceService
            .checkLegalDocumentAcceptance({
              userId: this.authFacade.user()?.id as string,
              legalDocumentType: LegalDocumentType.CCATermsOfUse,
            })
            .pipe(
              tap((result) => {
                if (result.userAccepted) return;

                this.dialog
                  .open(TermsDialogComponent, {
                    data: {
                      documentType: LegalDocumentType.CCATermsOfUse,
                      userId: this.authFacade.user()?.id as string,
                      isNewUser: false,
                    },
                  })
                  .afterClosed()
                  .subscribe((termsAccepted) => {
                    if (!termsAccepted) this.authFacade.logout();
                  });
              }),
            );
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
