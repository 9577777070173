import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
  exhaustMap,
  of,
  race,
  timer,
  switchMap,
  map,
  catchError,
  tap,
  delay,
} from 'rxjs';
import * as refreshActions from './refresh.actions';
import { selectRealUser } from '../authentication.selectors';
import { authenticationTimeOutInMs } from '../constants';
import {
  AuthenticationService,
  toAuthModel,
} from '@cca-infra/user-management/v1';
import { MatDialog } from '@angular/material/dialog';
import { RebrandingModalComponent } from '../../rebranding-modal';
import * as loginActions from '../login/login.actions';

@Injectable()
export class AuthenticationRefreshEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private authService = inject(AuthenticationService);
  private dialog = inject(MatDialog);

  readonly refreshAccessToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshActions.refreshAccessToken),
      concatLatestFrom(() => [this.store.select(selectRealUser)]),
      exhaustMap((results) => {
        const userId = results[1]?.userId;
        const showMessage = results[0]?.showMessage ?? true;
        const redirect = results[0]?.redirect ?? true;

        // if refresh token undefined/null we return a refreshAccessTokenFailure action
        if (!userId) {
          return of(
            refreshActions.refreshAccessTokenFailure({
              error: 'No user to refresh the token for',

              // if not logged in don't need to show a message
              showMessage: false,

              // and redirect should always be false ( might be on quest page )
              redirect: false,
            }),
          );
        }

        return race(
          // timer that after 20 seconds will emit a error "timeout"
          timer(authenticationTimeOutInMs).pipe(
            switchMap(() =>
              of(
                refreshActions.refreshAccessTokenFailure({
                  error: 'Timeout while requesting a new accessToken',
                  showMessage: showMessage,
                  redirect: true,
                }),
              ),
            ),
          ),

          // request a new accessToken
          this.authService.refreshAccessToken(userId).pipe(
            // if we get data map it to a success action
            map((authenticationData) => {
              return refreshActions.refreshAccessTokenSuccess({
                authentication: toAuthModel(authenticationData),
              });
            }),

            // if there is a error we will map it to a fail action
            catchError(() =>
              of(
                refreshActions.refreshAccessTokenFailure({
                  error: 'Error while requesting a new accessToken',
                  showMessage: showMessage,
                  redirect: redirect,
                }),
              ),
            ),
          ),
        );
      }),
    );
  });

  readonly acknowledgeRebranding$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          refreshActions.refreshAccessTokenSuccess,
          loginActions.authenticateSuccessAction,
        ),
        delay(1000),
        tap(() => {
          const modalAcknowledged = localStorage.getItem(
            'rebrandingModalAcknowledged',
          );

          this.openRebrandingModal(modalAcknowledged);
        }),
      );
    },
    { dispatch: false },
  );

  private openRebrandingModal(modalAcknowledged?: string | null) {
    const today = new Date().getTime();
    const startDate = new Date('2024-01-24T00:00:00Z').getTime();
    const endDate = new Date('2024-01-31T23:59:59Z').getTime();
    if (!modalAcknowledged && today >= startDate && today <= endDate) {
      this.dialog.open(RebrandingModalComponent);
    }
  }
}
