import { FormControl, FormGroup, Validators } from '@angular/forms';

export const AuthenticationFormFactory = () => {
  return new FormGroup({
    username: new FormControl<string | null>(null, {
      validators: [Validators.required],
    }),
    password: new FormControl<string | null>(null, {
      validators: [Validators.required],
    }),
  });
};

export type AuthenticationForm = ReturnType<typeof AuthenticationFormFactory>;
