import { DerivedAuthViewModel } from '@cca-infra/user-management/v1';
import { createAction, props } from '@ngrx/store';

// authentication
export const authenticateAction = createAction(
  '[Authentication] login',
  props<{
    username: string;
    password: string;
  }>(),
);

export const authenticateSsoAction = createAction(
  '[Authentication] loginSingleSignOnLocal',
  props<{
    accessToken: string;
  }>(),
);

export const authenticateSuccessAction = createAction(
  '[Authentication] loginSuccess',
  props<{ authentication: DerivedAuthViewModel }>(),
);

export const authenticateFailureAction = createAction(
  '[Authentication] loginFailure',
  props<{ error: string }>(),
);
