import { on } from '@ngrx/store';
import { AuthenticationState, initialState } from '../authentication.state';
import { logout } from './logout.actions';
import * as synchronizeActions from '../synchronize/synchronize.actions';

export const logoutReducer = [
  // on logging out we reset state to initial state
  on(
    logout,
    synchronizeActions.SynchronizeLogoutCompleteAction,
    (): AuthenticationState => {
      return {
        ...initialState,
      };
    },
  ),
];
