import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, switchMap, tap, timer } from 'rxjs';
import { AuthenticationBroadcastChannel } from './broadcast-channel';
import { BroadcastMessageTypes } from './broadcast-messages';
import * as loginActions from '../login/login.actions';
import * as logoutActions from '../logout/logout.actions';
import * as refreshActions from '../refresh/refresh.actions';
import * as synchronizeActions from './synchronize.actions';
import { Store } from '@ngrx/store';
import {
  selectRefreshTokenInProgress,
  selectAuthenticationInProgress,
} from '../authentication.selectors';
import { authenticationTimeOutInMs } from '../constants';

@Injectable()
export class AuthenticationSynchronizeEffects {
  private actions$ = inject(Actions);
  private broadcastChannel = inject(AuthenticationBroadcastChannel);
  private store = inject(Store);

  // authentication signals
  private refreshingInProgress = this.store.selectSignal(
    selectRefreshTokenInProgress,
  );
  private authenticationInProgress = this.store.selectSignal(
    selectAuthenticationInProgress,
  );

  // login
  readonly login$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginActions.authenticateAction),
        tap(() => {
          this.broadcastChannel.postMessage({
            type: BroadcastMessageTypes.Action,
            action: synchronizeActions.SynchronizeAuthenticateAction(),
          });
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  readonly loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginActions.authenticateSuccessAction),
        tap((action) => {
          this.broadcastChannel.postMessage({
            type: BroadcastMessageTypes.Action,
            action: synchronizeActions.SynchronizeAuthenticateSuccessAction({
              authentication: action.authentication,
            }),
          });
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  readonly loginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginActions.authenticateFailureAction),
        tap((action) => {
          this.broadcastChannel.postMessage({
            type: BroadcastMessageTypes.Action,
            action: synchronizeActions.SynchronizeAuthenticateFailureAction({
              error: action.error,
            }),
          });
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  readonly loginTimeout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(synchronizeActions.SynchronizeAuthenticateSuccessAction),
      switchMap(() => {
        return timer(authenticationTimeOutInMs).pipe(
          switchMap(() => {
            // if we still are in that state it is possible that the pc/browser/tab crashed or in other ways closed before it put back to false
            // therefore this is a failsafe, to put it back to it's initial state of false
            if (this.authenticationInProgress()) {
              return of(synchronizeActions.clearAuthenticationProgressAction());
            }

            return EMPTY;
          }),
        );
      }),
    );
  });

  // refresh
  readonly refresh$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(refreshActions.refreshAccessToken),
        tap(() => {
          this.broadcastChannel.postMessage({
            type: BroadcastMessageTypes.Action,
            action: synchronizeActions.SynchronizeRefreshAccessToken(),
          });
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  readonly refreshSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(refreshActions.refreshAccessTokenSuccess),
        tap((action) => {
          this.broadcastChannel.postMessage({
            type: BroadcastMessageTypes.Action,
            action:
              synchronizeActions.SynchronizeRefreshAccessTokenSuccessAction({
                authentication: action.authentication,
              }),
          });
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  readonly refreshFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(refreshActions.refreshAccessTokenFailure),
        tap((action) => {
          this.broadcastChannel.postMessage({
            type: BroadcastMessageTypes.Action,
            action: synchronizeActions.SynchronizeRefreshAccessTokenFailure({
              error: action.error,
            }),
          });
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  readonly refreshTimeout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(synchronizeActions.SynchronizeRefreshAccessToken),
      switchMap(() => {
        return timer(authenticationTimeOutInMs).pipe(
          switchMap(() => {
            // if we still are in that state it is possible that the pc/browser/tab crashed or in other ways closed before it put back to false
            // therefore this is a failsafe, to put it back to it's initial state of false
            if (this.refreshingInProgress()) {
              return of(synchronizeActions.clearRefreshProgressAction());
            }

            return EMPTY;
          }),
        );
      }),
    );
  });

  // logout
  readonly logoutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          logoutActions.logoutComplete,
          refreshActions.refreshAccessTokenFailure,
        ),
        tap(() => {
          this.broadcastChannel.postMessage({
            type: BroadcastMessageTypes.Action,
            action: synchronizeActions.SynchronizeLogoutCompleteAction(),
          });
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
