<ng-container *transloco="let t; read: 'rebranding.modal'">
  <div class="dialog-container">
    <header>
      <h2>{{ t('header') }}</h2>
      <button
        [mat-dialog-close]="null"
        class="dialog-close-button"
        (click)="acknowledge()"
      >
        <cca-icon icon="xmark"></cca-icon>
      </button>
    </header>

    <main class="flex w-[720px] flex-col items-center gap-6 p-6 text-center">
      <img class="w-[512px]" src="assets/images/CCA-old-logo-transition.svg" />
      <h2 class="text-base font-normal text-cca-neutrals-800">
        {{ t('bodyMessage') }}
      </h2>
    </main>

    <footer>
      <button
        mat-stroked-button
        color="primary"
        [mat-dialog-close]="false"
        (click)="acknowledge()"
      >
        {{ t('closeBtn') }}
      </button>

      <button
        mat-flat-button
        [color]="'primary'"
        cdkFocusInitial
        (click)="openLearnMorePage()"
      >
        {{ t('learnMoreBtn') }}
      </button>
    </footer>
  </div>
</ng-container>
