import { ApplicationRef, Injectable, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
  debounceTime,
  skip,
  switchMap,
  catchError,
  EMPTY,
  tap,
  of,
} from 'rxjs';
import { getCanonicalLocale } from '@cca-common/translations';
import {
  ClockPreferenceType,
  UserService,
} from '@cca-infra/user-management/v1';
import {
  selectAuthenticated,
  selectAuthenticationInProgress,
  selectClockPreference,
  selectLanguage,
  selectLocale,
  selectRefreshTokenInProgress,
} from '../authentication.selectors';
import * as userActions from './user.actions';
import * as refreshActions from '../refresh/refresh.actions';
import * as loginActions from '../login/login.actions';
import * as synchronizeActions from '../synchronize/synchronize.actions';
import { NAVIGATOR } from '@cca-common/environment';

@Injectable()
export class AuthenticationUserEffects {
  private translocoService = inject(TranslocoService);
  private translocoLocaleService = inject(TranslocoLocaleService);
  private actions$ = inject(Actions);
  private store = inject(Store);
  private userService = inject(UserService);
  private applicationRef = inject(ApplicationRef);
  private navigator = inject(NAVIGATOR);

  // authentication signals
  private refreshingInProgress = this.store.selectSignal(
    selectRefreshTokenInProgress,
  );
  private authenticationInProgress = this.store.selectSignal(
    selectAuthenticationInProgress,
  );
  private currentLanguage = this.store.selectSignal(selectLanguage);
  private currentLocale = this.store.selectSignal(selectLocale);

  readonly saveLanguageToBackend$ = createEffect(() => {
    return this.translocoService.langChanges$.pipe(
      // on init the langChanges can emit a few times ( at least once ), we debounce them and ignore first emission
      debounceTime(500),
      skip(1),
      concatLatestFrom(() => [this.store.select(selectAuthenticated)]),
      switchMap((results) => {
        const authenticated = results[1];
        if (!authenticated) {
          return EMPTY;
        }

        const refreshing = this.refreshingInProgress();
        if (refreshing) {
          return EMPTY;
        }

        const authentication = this.authenticationInProgress();
        if (authentication) {
          return EMPTY;
        }

        const currentLanguage = this.currentLanguage();
        const languageName = results[0];
        if (currentLanguage === languageName) {
          return EMPTY;
        }

        return this.userService.setUserLanguage(languageName).pipe(
          switchMap((languageDidChange) => {
            if (!languageDidChange) {
              return EMPTY;
            }

            return of(
              refreshActions.refreshAccessToken({
                redirect: true,
                showMessage: true,
              }),
            );
          }),
          catchError(() => {
            console.error(
              'Error while requesting updating user language towards backend',
            );
            return EMPTY;
          }),
        );
      }),
    );
  });

  readonly syncUserLanguageWithTransloco$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          userActions.syncUserLanguageWithTransloco,
          // login
          loginActions.authenticateSuccessAction,
          synchronizeActions.SynchronizeAuthenticateSuccessAction,

          // refresh
          refreshActions.refreshAccessTokenSuccess,
          synchronizeActions.SynchronizeRefreshAccessTokenSuccessAction,
        ),
        // set language
        tap(() => {
          const language = this.currentLanguage();
          if (language && language !== this.translocoService.getActiveLang()) {
            this.translocoService.setActiveLang(language);
          }
        }),

        // set locale
        tap(() => {
          const clockPreference = this.store.selectSignal(
            selectClockPreference,
          );
          const locale = this.currentLocale();
          // if we have a locale that is not null/undefined or empty string
          if (locale && locale !== '') {
            const updatedLocale = new Intl.Locale(getCanonicalLocale(locale), {
              hourCycle:
                clockPreference() === ClockPreferenceType.MilitaryTime
                  ? 'h23'
                  : 'h12',
            }).toString();
            this.translocoLocaleService.setLocale(updatedLocale);
          }
          // else we try to set the lang based on the browser settings, this is mostly used for public pages
          else {
            for (const lang of this.navigator?.languages ?? []) {
              try {
                // get canonical locale can throw error if incorrect locale information is provided
                const canonicalLocale = getCanonicalLocale(lang);
                this.translocoLocaleService.setLocale(canonicalLocale);

                // if we succeeded we can break the loop
                break;
              } catch (e) {
                console.warn(e);
              }
            }
          }
        }),

        // trigger a changeDetection
        tap(() => {
          this.applicationRef.tick();
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
