import { Signal, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectPermissions, selectRealPermissions } from '../state';
import { checkPermission, PermissionData } from '../util';
import { PermissionKey, PermissionType } from '@cca-infra/user-management/v1';

/**
 * @param permissionSignal Signal to retrieve the UserData
 * @param permissions permission(s) to check
 * @param permissionType type of permission(s) to check
 * @returns Signal<boolean>
 */
function permissionSignalHelper(
  permissionEntriesSignal: Signal<PermissionData>,
  permissions: PermissionKey | PermissionKey[],
  permissionType?: PermissionType | PermissionType[] | undefined,
) {
  return computed(() => {
    const permissionEntries = permissionEntriesSignal();
    return checkPermission(permissionEntries, permissions, permissionType);
  });
}

/**
 * @param permissions permission(s) to check
 * @param permissionType type of permission(s) to check
 * @returns Signal<boolean>
 */
export function createPermissionSignal(
  permissions: PermissionKey | PermissionKey[],
  permissionType?: PermissionType | PermissionType[] | undefined,
) {
  const store = inject(Store);
  const permissionSignal = store.selectSignal(selectPermissions);
  return permissionSignalHelper(permissionSignal, permissions, permissionType);
}

/**
 * @param permissions permission(s) to check
 * @param permissionType type of permission(s) to check
 * @returns Signal<boolean>
 */
export function createRealPermissionSignal(
  permissions: PermissionKey | PermissionKey[],
  permissionType?: PermissionType | PermissionType[] | undefined,
) {
  const store = inject(Store);
  const permissionSignal = store.selectSignal(selectRealPermissions);
  return permissionSignalHelper(permissionSignal, permissions, permissionType);
}
